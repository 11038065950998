var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "listing-content-kanban"
    }
  }, [_c('v-row', {
    class: {
      'add-leads-row-dropdown': _vm.newLeadSelectionOpen,
      'add-leads-row-button': !_vm.newLeadSelectionOpen
    }
  }, [!_vm.newLeadSelectionOpen ? _c('button', {
    staticClass: "outlined-small-button",
    staticStyle: {
      "height": "32px",
      "align-items": "center"
    },
    on: {
      "click": _vm.openAddNewLeadSelection
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/plusSign.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.$t("AddLeadToKanban")) + " ")])]) : _vm._e(), _vm.newLeadSelectionOpen ? _c('ClientListSearch', {
    ref: "dropdown",
    attrs: {
      "auto-focus": true
    },
    on: {
      "addNew": _vm.openAddNewContact,
      "itemChosen": _vm.passCustomerObject,
      "closed": _vm.closeAddNewLeadsSelection
    }
  }) : _vm._e()], 1), _c('div', {
    staticStyle: {
      "position": "relative",
      "margin-top": "24px"
    },
    attrs: {
      "name": "buttons-container"
    }
  }), _c('v-row', {
    key: _vm.leadsUpdater,
    staticClass: "leads-row",
    staticStyle: {
      "margin-top": "56px"
    }
  }, [_c('v-row', {
    staticClass: "columns-wrapper"
  }, _vm._l(_vm.listingObjectFunnels, function (value, propertyName) {
    return _c('div', {
      key: JSON.stringify(propertyName),
      staticClass: "column-width gray-content",
      class: {
        'column-width green-content': propertyName === 'Negotiation & Contract',
        'column-width light-grey': propertyName === 'Matches'
      },
      on: {
        "dragover": function ($event) {
          return _vm.allowDrop($event);
        },
        "drop": function ($event) {
          return _vm.drop($event, _vm.listing, _vm.listing._id, _vm.listing.street, _vm.listing.houseNumber, propertyName);
        }
      }
    }, [_c('v-row', {
      staticStyle: {
        "padding-top": "10px",
        "padding-left": "8px",
        "padding-right": "8px",
        "padding-bottom": "26px"
      },
      attrs: {
        "justify": "space-between"
      }
    }, [propertyName === 'Matches' ? _c('p', {
      staticClass: "content-small"
    }, [_vm._v(" " + _vm._s(_vm.$t("Suggestions")) + " ")]) : propertyName === 'Lost / Archived' ? _c('p', {
      staticClass: "content-small"
    }, [_vm._v(" " + _vm._s(_vm.$t("LostArchived")) + " ")]) : propertyName === 'Negotiation & Contract' ? _c('p', {
      staticClass: "content-small",
      staticStyle: {
        "color": "#0aaf60"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("Negotiation & Contract")) + " ")]) : propertyName === 'Offers' ? _c('p', {
      staticClass: "content-small"
    }, [_vm._v(" " + _vm._s(_vm.$t("SendOffer")) + " ")]) : _c('p', {
      staticClass: "content-small"
    }, [_vm._v(" " + _vm._s(_vm.$t(propertyName)) + " ")]), propertyName !== 'Negotiation & Contract' ? _c('div', {
      staticStyle: {
        "background-color": "#75787a",
        "border-radius": "12px",
        "width": "24px",
        "height": "20px",
        "padding": "2px 8px",
        "display": "flex",
        "place-content": "center",
        "flex-wrap": "nowrap",
        "align-content": "center",
        "justify-content": "center",
        "align-items": "center"
      }
    }, [_c('p', {
      staticClass: "leads-count"
    }, [_vm._v(_vm._s(value.length))])]) : _vm._e(), propertyName === 'Negotiation & Contract' ? _c('div', {
      staticStyle: {
        "background-color": "#0aaf60",
        "border-radius": "12px",
        "width": "24px",
        "height": "20px",
        "padding": "2px 8px",
        "display": "flex",
        "place-content": "center",
        "flex-wrap": "nowrap",
        "align-content": "center",
        "justify-content": "center",
        "align-items": "center"
      }
    }, [_c('p', {
      staticClass: "leads-count"
    }, [_vm._v(_vm._s(value.length))])]) : _vm._e()]), _c('draggable', {
      staticClass: "scrollable",
      attrs: {
        "list": value,
        "animation": 200,
        "ghost-class": "ghost-card",
        "group": "tasks",
        "id": propertyName
      },
      on: {
        "change": _vm.setMovedItem,
        "end": _vm.move
      }
    }, _vm._l(value, function (task) {
      return _c('task-card', {
        key: task.leadId,
        staticClass: "mt-3 cursor-move",
        attrs: {
          "lead-added": _vm.leadAdded,
          "from-project": true,
          "task": task,
          "id": task._id
        },
        on: {
          "leadClicked": _vm.openLead
        }
      });
    }), 1)], 1);
  }), 0)], 1), _vm.contactModalOpen ? _c('AddModal', {
    attrs: {
      "not-contact-view-route": true,
      "isEdit": false
    },
    on: {
      "newAddedContact": _vm.passCustomerIdAndObject,
      "closed": function ($event) {
        return _vm.closeAddModal();
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }