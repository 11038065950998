<template>
  <div id="listing-content-kanban">
    <v-row
      :class="{
        'add-leads-row-dropdown': newLeadSelectionOpen,
        'add-leads-row-button': !newLeadSelectionOpen,
      }"
    >
      <button
        @click="openAddNewLeadSelection"
        v-if="!newLeadSelectionOpen"
        class="outlined-small-button"
        style="height: 32px; align-items: center"
      >
        <img src="@/assets/images/plusSign.svg" alt="" />
        <p>
          {{ $t("AddLeadToKanban") }}
        </p>
      </button>
      <ClientListSearch
        :auto-focus="true"
        ref="dropdown"
        v-if="newLeadSelectionOpen"
        @addNew="openAddNewContact"
        @itemChosen="passCustomerObject"
        @closed="closeAddNewLeadsSelection"
      >
      </ClientListSearch>
    </v-row>
    <div
      name="buttons-container"
      style="position: relative; margin-top: 24px"
    ></div>
    <v-row :key="leadsUpdater" class="leads-row" style="margin-top: 56px">
      <v-row class="columns-wrapper">
        <div
          v-for="(value, propertyName) in listingObjectFunnels"
          :key="JSON.stringify(propertyName)"
          v-bind:class="{
            'column-width green-content':
              propertyName === 'Negotiation & Contract',
            'column-width light-grey': propertyName === 'Matches',
          }"
          class="column-width gray-content"
          @dragover="allowDrop($event)"
          @drop="
            drop(
              $event,
              listing,
              listing._id,
              listing.street,
              listing.houseNumber,
              propertyName
            )
          "
        >
          <v-row
            justify="space-between"
            style="
              padding-top: 10px;
              padding-left: 8px;
              padding-right: 8px;
              padding-bottom: 26px;
            "
          >
            <p v-if="propertyName === 'Matches'" class="content-small">
              {{ $t("Suggestions") }}
            </p>
            <p
              v-else-if="propertyName === 'Lost / Archived'"
              class="content-small"
            >
              {{ $t("LostArchived") }}
            </p>
            <p
              v-else-if="propertyName === 'Negotiation & Contract'"
              class="content-small"
              style="color: #0aaf60"
            >
              {{ $t("Negotiation & Contract") }}
            </p>
            <p v-else-if="propertyName === 'Offers'" class="content-small">
              {{ $t("SendOffer") }}
            </p>
            <p v-else class="content-small">
              {{ $t(propertyName) }}
            </p>
            <div
              v-if="propertyName !== 'Negotiation & Contract'"
              style="
                background-color: #75787a;
                border-radius: 12px;
                width: 24px;
                height: 20px;
                padding: 2px 8px;
                display: flex;
                place-content: center;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;
              "
            >
              <p class="leads-count">{{ value.length }}</p>
            </div>
            <div
              v-if="propertyName === 'Negotiation & Contract'"
              style="
                background-color: #0aaf60;
                border-radius: 12px;
                width: 24px;
                height: 20px;
                padding: 2px 8px;
                display: flex;
                place-content: center;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;
              "
            >
              <p class="leads-count">{{ value.length }}</p>
            </div>
          </v-row>
          <draggable
            :list="value"
            :animation="200"
            ghost-class="ghost-card"
            group="tasks"
            @change="setMovedItem"
            @end="move"
            :id="propertyName"
            class="scrollable"
          >
            <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
            <task-card
              v-for="task in value"
              :lead-added="leadAdded"
              @leadClicked="openLead"
              :from-project="true"
              :key="task.leadId"
              :task="task"
              :id="task._id"
              class="mt-3 cursor-move"
            ></task-card>
          </draggable>
        </div>
      </v-row>
    </v-row>
    <AddModal
      v-if="contactModalOpen"
      :not-contact-view-route="true"
      :isEdit="false"
      @newAddedContact="passCustomerIdAndObject"
      @closed="closeAddModal()"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import draggable from "vuedraggable-rp";
import TaskCard from "../Listings/TaskCard.vue";
import nanoid from "nanoid";
import AddModal from "@/components/Kliendiregister/AddModal.vue";
import listingApi from "@/http/listing";
import ClientListSearch from "../common/ClientListSearch";
import projectApi from "@/http/project"
export default {
  name: "LeadsKanban",
  components: {
    ClientListSearch,
    draggable,
    TaskCard,
    AddModal,
  },
  props: ["id", "listing", "kanbanUpdater", "project", "isProjectInactive"],
  data() {
    return {
      leadsUpdater: 0,
      listings: null,
      listingObject: null,
      leadPanelOpen: false,
      leadAdded: 1,
      movedElement: null,
      isLoaded: false,
      arranged: false,
      leads: null,
      isLeadsOpen: false,
      listingColumns: [],
      contactModalOpen: false,
      listingFunnelsActive: [],
      newLeadSelectionOpen: false,
    };
  },
  created() {
    this.setup();
    this.updateKanban();
  },
  mounted() {},
  watch: {
    listingsLoaded(value) {
      if (value) {
        this.setup();
      }
    },
    async kanbanUpdater() {
      const response = await axios.get(`/api/listing/${this.listing._id}`);
      this.listingObject = response.data[0];
      await this.getListingSuggestions([this.listingObject]);
    },
    async listing() {
      this.listingObject = Object.assign({}, this.listing);
      await this.getListingSuggestions([this.listingObject]);
    },
  },
  computed: {
    ...mapGetters(["filteredListings", "listingsLoaded", "user"]),
    listingObjectFunnels() {
      if (this.isProjectInactive) {
        let listingObjectFunnelsCopy = this.listingObject.funnels;
        delete listingObjectFunnelsCopy.Matches;
        return listingObjectFunnelsCopy;
      } else {
        return this.listingObject.funnels;
      }
    },
  },
  methods: {
    ...mapActions([
      "getListings",
      "addLeadIdToOffersCount",
      "removeLeadInOffersCount",
    ]),
    setup() {
      for (const [key, value] of Object.entries(this.listing.funnels)) {
        value.forEach((funnel, index) => {
          if (funnel.statusCode === 0) {
            console.log(key);
            value.splice(index, 1);
          }
        });
      }
      this.listingObject = Object.assign({}, this.listing);

      this.getListingSuggestions([this.listingObject]);
    },
    async updateKanban() {
      const response = await axios.get(`/api/listing/${this.listing._id}`);
      this.listingObject = response.data[0];
      await this.getListingSuggestions([this.listingObject]);
    },
    openAddNewLeadSelection() {
      this.newLeadSelectionOpen = true;
      this.$nextTick(() => {
        this.$refs.dropdown.focusInputElement();
      });
    },
    closeAddNewLeadsSelection() {
      this.newLeadSelectionOpen = false;
    },
    closeAddModal() {
      this.contactModalOpen = false;
    },
    openAddNewContact() {
      this.contactModalOpen = true;
    },
    passCustomerIdAndObject(customer, customerId) {
      if (!customer || !customerId) return;
      this.addNewContactToFunnel(customerId, customer.customerName);
    },
    passCustomerObject(customer) {
      if (!customer) return;
      this.addNewContactToFunnel(customer.customerId, customer.customerName);
    },
    async addNewContactToFunnel(customerId, customerName) {
      this.closeAddNewLeadsSelection();
      let listing = this.listing;
      let listingId = this.listing._id;
      let propertyName = "Interested";
      let leadId = customerId;
      if (!leadId || leadId === "") {
        return;
      }
      let newFunnel2 = null;
      let listingTitleObject = {
        street: listing.street,
        houseNumber: listing.houseNumber,
        district: listing.district,
        cityPart: listing.cityPart,
        city: listing.city,
        county: listing.county,
      };

      listingTitleObject = Object.fromEntries(
        Object.entries(listingTitleObject).filter(
          //eslint-disable-next-line no-unused-vars
          ([_, v]) => v != null && v != "" && v != "undefined"
        )
      );
      let listingTitle = "";
      for (const [key, value] of Object.entries(listingTitleObject)) {
        if (key && value != null && value != "" && value != "undefined") {
          listingTitle = listingTitle + value + ", ";
        }
      }

      let titleEnd = listingTitle.slice(listingTitle.length - 2);
      if (titleEnd === ", ") {
        listingTitle = listingTitle.slice(0, -2);
      }
      newFunnel2 = {
        leadId,
        step: propertyName,
        listingId: listingId,
        listingName: listingTitle,
        creator: {
          userId: this.user._id,
          name: this.user.name,
          email: this.user.email,
          companyId: this.user.companyId,
          companyName: this.user.companyName,
        },
      };

      await axios.post(`/api/contact/leads/${leadId}`, newFunnel2);
      await axios.patch(`/api/listing/${listingId}`, newFunnel2);
      const response = await axios.get(`/api/listing/${listingId}`);
      this.listingObject = response.data[0];
      //this.listings = this.filteredListings;
      this.$emit("refreshLeads");
      await this.getListingSuggestions([this.listingObject]);
      if (response.status === 200) {
        this.showLeadAddSuccessToast(customerName);
      }
      this.leadAdded++;
    },
    showLeadAddSuccessToast(customerName) {
      this.toastSuccess(customerName + " " + this.$t("AddedToInterests"));
    },
    async getListingSuggestions(listingToCheck) {
      let listings = listingToCheck;
      this.autoSuggestionsLoaded = false;
      let response = await listingApi.getListingAutoSuggestions(listings);
      let matches = response.data;

      for (let listing of listings) {
        listing.funnelsLoaded = false;
        for (let match of matches) {
          if (match.listingId !== listing._id) {
            continue;
          }
          let leadAlreadyExists = false;
          for (const [key, value] of Object.entries(listing.funnels)) {
            if (key === "Matches") {
              continue;
            }
            value.forEach((funnel) => {
              if (funnel.leadId === match.customerId) {
                leadAlreadyExists = true;
              }
            });
          }
          if (leadAlreadyExists) {
            continue;
          } else {
            const lead = {
              _id: nanoid(16),
              leadId: match.customerId,
              leadName: match.leadName,
              listingId: match.listingId,
              dateChanged: match.createdAt,
               interestId: match.interestId
            };
            listing.funnels["Matches"].push(lead);
          }
        }
        //remove duplicate suggestions
        const duplicateSet = new Set();
        listing.funnels["Matches"] = listing.funnels["Matches"].filter((el) => {
          const dupe = duplicateSet.has(el.leadId);
          duplicateSet.add(el.leadId);
          return !dupe;
        });
        listing.funnelsLoaded = true;
        //this.listingObject = listing;
        this.leadsUpdater++;

        let funnelCount = 0;
        //eslint-disable-next-line
        for (const [key, value] of Object.entries(listing.funnels)) {
          value.forEach(() => {
            funnelCount++;
          });
        }

        this.$emit("funnelCount", {
          listingId: this.listing._id,
          leadsCount: funnelCount,
        });
      }
    },
    openLeads() {
      this.isLeadsOpen = !this.isLeadsOpen;
      if (this.project) {
        let listingContent = document.getElementById("main-container");
        let listingHeader = document.getElementById("listing-header");
        let sidepanelLeads = document.getElementById("sidepanel-leads");
        if (this.isLeadsOpen) {
          listingContent.classList.remove("slide-animation-close");
          listingHeader.classList.remove("slide-animation-close");
          sidepanelLeads.classList.remove("slide-close");
          listingContent.classList.add("slide-animation-open");
          listingHeader.classList.add("slide-animation-open");
          sidepanelLeads.classList.add("slide-open");
          this.$emit("leadsOpened");
        } else {
          listingContent.classList.remove("slide-animation-open");
          listingHeader.classList.remove("slide-animation-open");
          sidepanelLeads.classList.remove("slide-open");
          listingContent.classList.add("slide-animation-close");
          listingHeader.classList.add("slide-animation-close");
          sidepanelLeads.classList.add("slide-close");
          this.$emit("leadsClosed");
        }
      } else if (!this.project) {
        let listingHeader = document.getElementById("listing-header");
        let listingContent = document.getElementById("listing-content-kanban");
        let sidepanelLeads = document.getElementById("sidepanel-leads");
        if (this.isLeadsOpen) {
          listingHeader.classList.remove("slide-animation-close");
          listingContent.classList.remove("slide-animation-close");
          sidepanelLeads.classList.remove("slide-close");
          listingHeader.classList.add("slide-animation-open");
          listingContent.classList.add("slide-animation-open");
          sidepanelLeads.classList.add("slide-open");
        } else {
          listingHeader.classList.remove("slide-animation-open");
          listingContent.classList.remove("slide-animation-open");
          sidepanelLeads.classList.remove("slide-open");
          listingHeader.classList.add("slide-animation-close");
          listingContent.classList.add("slide-animation-close");
          sidepanelLeads.classList.add("slide-close");
        }
      }
    },
    filterBy(ary, prop) {
      let seen = new Set();
      return ary.filter(
        (item) => !seen.has(item[prop]) && seen.add(item[prop])
      );
    },
    openLead(lead) {
      document.documentElement.style.overflow = "hidden";
      this.$emit("leadOpened", lead);
    },
    allowDrop(ev) {
      ev.preventDefault();
    },
    async drop(
      ev,
      listing,
      listingId,
      listingStreet,
      listingHouseNr,
      propertyName
    ) {
      ev.preventDefault();
      let leadId = ev.dataTransfer.getData("lead");
      if (!leadId || leadId === "") {
        return;
      }
      let newFunnel2 = null;

      let listingTitleObject = {
        street: listing.street,
        houseNumber: listing.houseNumber,
        district: listing.district,
        cityPart: listing.cityPart,
        city: listing.city,
        county: listing.county,
      };

      listingTitleObject = Object.fromEntries(
        Object.entries(listingTitleObject).filter(
          //eslint-disable-next-line no-unused-vars
          ([_, v]) => v != null && v != "" && v != "undefined"
        )
      );
      let listingTitle = "";
      for (const [key, value] of Object.entries(listingTitleObject)) {
        if (key && value != null && value != "" && value != "undefined") {
          listingTitle = listingTitle + value + ", ";
        }
      }

      let titleEnd = listingTitle.slice(listingTitle.length - 2);
      if (titleEnd === ", ") {
        listingTitle = listingTitle.slice(0, -2);
      }

      if (propertyName === "Matches" || propertyName === "Huvitatud") {
        newFunnel2 = {
          leadId,
          step: "Interested",
          listingId: listingId,
          listingName: listingTitle,
        };
      } else if (propertyName === 0) {
        newFunnel2 = {
          leadId,
          step: "Interested",
          listingId: listingId,
          listingName: listingTitle,
        };
      } else {
        newFunnel2 = {
          leadId,
          step: propertyName,
          listingId: listingId,
          listingName: listingTitle,
        };
      }
      await axios.post(`/api/contact/leads/${leadId}`, newFunnel2);
      await axios.patch(`/api/listing/${listingId}`, newFunnel2);
      const response = await axios.get(`/api/listing/${listingId}`);
      this.listingObject = response.data[0];
      //this.listings = this.filteredListings;
      this.$emit("refreshLeads");
      await this.getListingSuggestions([this.listingObject]);

      this.leadAdded++;
    },
    setMovedItem(evt) {
      if (evt.removed) {
        this.movedElement = evt.removed.element;
      }
    },
    async move(evt) {
      let changedLead = this.movedElement;

      switch (evt.to.id) {
        case "Interested":
          changedLead.step = "Interested";
          break;
        case "Huvitatud":
          changedLead.step = "Interested";
          break;
        case "Viewed":
          changedLead.step = "Viewed";
          break;
        case "Vaadatud":
          changedLead.step = "Viewed";
          break;
        case "Negotiation & Contract":
          changedLead.step = "Negotiation & Contract";
          break;
        case "Läbirääkimised & Leping":
          changedLead.step = "Negotiation & Contract";
          break;
        case "Offers":
          changedLead.step = "Offers";
          break;
        case "Pakkumised":
          changedLead.step = "Offers";
          break;
        case "Lost / Archived":
          changedLead.step = "Lost / Archived";
          break;
        case "Kaotatud / Arhiveeritud":
          changedLead.step = "Lost / Archived";
          break;
        case "Matches":
          changedLead.step = "Matches";
          break;
        case "Soovitused":
          changedLead.step = "Matches";
          break;
      }

      if (changedLead.step === "Matches") {
        changedLead.step = evt.from.id;
        await axios.post(
          `/api/contact/leads/delete/${changedLead.leadId}`,
          changedLead
        );
        await axios.post(
          `/api/listing/leads/delete/${changedLead.listingId}`,
          changedLead
        );
        return;
      } else if (changedLead.step === "Offers") {
        await this.addLeadIdToOffersCount(changedLead.leadId);
      }

      let listingId = changedLead.listingId;
      changedLead.previousStep = evt.from.id;

      if (changedLead.previousStep === "Offers") {
        await this.removeLeadInOffersCount(changedLead.leadId);
      }

      let listing = this.listing;
      let listingTitleObject = {
        street: listing.street,
        houseNumber: listing.houseNumber,
        district: listing.district,
        cityPart: listing.cityPart,
        city: listing.city,
        county: listing.county,
      };

      listingTitleObject = Object.fromEntries(
        Object.entries(listingTitleObject).filter(
          //eslint-disable-next-line no-unused-vars
          ([_, v]) => v != null && v != "" && v != "undefined"
        )
      );
      let listingTitle = "";
      for (const [key, value] of Object.entries(listingTitleObject)) {
        if (key && value != null && value != "" && value != "undefined") {
          listingTitle = listingTitle + value + ", ";
        }
      }

      let titleEnd = listingTitle.slice(listingTitle.length - 2);
      if (titleEnd === ", ") {
        listingTitle = listingTitle.slice(0, -2);
      }
      changedLead.listingName = listingTitle;
      //eslint-disable-next-line
      let response1 = null;
      if (changedLead.module === 'projects') {
        let project = await axios.get(`/api/project/${changedLead.leadId}`);
        let interestId = project.data.interests[0]._id;
        changedLead.interestId = interestId;
        await projectApi.updateFunnel(changedLead.leadId, changedLead);
      } else if (changedLead.previousStep === "Matches") {
          response1 = await axios.post(`/api/contact/leads/${changedLead.leadId}`, changedLead);
      } else {
          response1 = await axios.patch(`/api/contact/leads/${changedLead.leadId}`, changedLead);
      }

      await axios.patch(`/api/listing/${listingId}`, changedLead);
      this.$emit("refreshLeads");
    },
  },
};
</script>
<style lang="scss" scoped>
.columns-wrapper {
  gap: 8px;
  margin-top: 24px;
  padding-bottom: 12px;
  //display: none;
  flex-wrap: nowrap;
  overflow-x: hidden;
}

.column-width {
  width: 100%;
  height: 360px;
}

.gray-content {
  background-color: #f4f5f7 !important;
  border-radius: 8px;
  padding: 8px;
}

.scrollable {
  overflow-y: auto;
  height: 100%;
  max-height: 290px;
}

.leads-row {
  //padding-left: 16px;
  //padding-right: 16px;
  background-color: transparent;
}

.add-leads-row-button {
  gap: 8px;
  position: absolute;
  margin-top: 24px;
}

.add-leads-row-dropdown {
  gap: 8px;
  position: absolute;
  margin-top: 16px;
  width: 320px;
}

.content-small {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.leads-count {
  color: white !important;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.cursor-move {
  width: 100%;
  padding: 8px;

  background: #ffffff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-top: 0px !important;
  margin-bottom: 4px;
}

.red-content {
  background-color: #fff0f0 !important;
}

.green-content {
  background-color: #f2ffe0 !important;
}
.light-grey {
  background-color: #fafafa !important;
}
#listing-header,
#listing-content-kanban {
  transition: width, margin-left 300ms ease-in-out;
}
.slide-animation {
  margin-left: 256px !important;
}
.slide-animation-close {
  margin-left: 0 !important;
}
.slide-animation-open {
  margin-left: 256px !important;
}
.slide-animation-close {
  margin-left: 0 !important;
}
</style>
